<template>
  <div class="preview-box">
    <div class="preview-box-inner">
      <v-icon
        class="no-print print-preview"
        @click="print()"
      >mdi-printer</v-icon>
      <v-icon
        class="no-print close-preview"
        @click="goBack()"
      >mdi-close-circle</v-icon>
      <div class="preview-inner">
        <v-row class="mt-0">
          <v-col class="col-6">
            <h2>Purchase Order #{{ po.id }}</h2>
            <div v-if="po.notes">
              <p class="text-orange mb-0">Notes:</p>
              <div v-html="po.notes"></div>
            </div>
          </v-col>
          <v-col class="col-2">&nbsp;</v-col>
          <v-col class="col-4">
            <img width="180px" src="../../assets/logo.png" alt="" />
            <p class="text-small">
              Unit X<br />
              Lodge Lane Industrial Estate<br />
              Tuxford, Newark, Notts<br />
              NG22 0NL<br />
              Office: 0800 8488414<br />
              Email: info@riggott.co.uk
            </p>
          </v-col>
        </v-row>
        <v-row dense class="mt-0">
          <v-col class="col-6">
            <div class="border-top border-left border-right border-bottom pa-1">
              <h4 class="text-orange">To:</h4>
              <p>
                {{ po.supplier.name }}<br />
                <span v-if="po.supplier.address_1">{{ po.supplier.address_1 }}, <br /></span>
                <span v-if="po.supplier.address_2">{{ po.supplier.address_2 }}, <br /></span>
                <span v-if="po.supplier.address_3">{{ po.supplier.address_3 }}, <br /></span>
                <span v-if="po.supplier.address_4">{{ po.supplier.address_4 }}, <br /></span>
                <span v-if="po.supplier.postcode">{{ po.supplier.postcode }}</span>
              </p>
            </div>
          </v-col>
          <v-col class="col-2">
            &nbsp;
          </v-col>
          <v-col class="col-4">
            <table class="compact-table">
              <tbody>
                <tr>
                  <td class="text-bold border-top border-left border-right text-orange pl-1 pr-1">Date</td>
                  <td class="border-top border-right pl-1 pr-1 text-center">{{ po.date_of_requisition | tinyDate }}</td>
                </tr>
                <tr>
                  <td class="text-bold border-top border-left border-right text-orange pl-1 pr-1">FTAO</td>
                  <td class="border-top border-right pl-1 pr-1 text-center">{{ po.for_the_attention_of }}</td>
                </tr>
                <tr>
                  <td class="text-bold border-top border-left border-right text-orange pl-1 pr-1">Order no</td>
                  <td class="border-top border-right pl-1 pr-1 text-center">{{ po.id }}</td>
                </tr>
                <tr>
                  <td class="text-bold border-top border-left border-right text-orange pl-1 pr-1">Quote ref</td>
                  <td class="border-top border-right pl-1 pr-1 text-center">{{ po.quotation_reference }}</td>
                </tr>
                <tr>
                  <td class="text-bold border-top border-left border-right text-orange pl-1 pr-1">Delivery date</td>
                  <td class="border-top border-right pl-1 pr-1 text-center">{{ po.delivery_date | tinyDate }}</td>
                </tr>
                <tr>
                  <td class="text-bold border-top border-left border-right text-orange pl-1 pr-1">SubContract</td>
                  <td class="border-top border-right pl-1 pr-1 text-center">{{ po.sub_contractor_reference }}</td>
                </tr>
                <tr>
                  <td class="text-bold border-top border-left border-right text-orange pl-1 pr-1">Contract date</td>
                  <td class="border-top border-right pl-1 pr-1 text-center">{{ po.contract_date | tinyDate }}</td>
                </tr>
                <tr>
                  <td class="text-bold border-top border-left border-right text-orange pl-1 pr-1">Raised by</td>
                  <td class="border-top border-right pl-1 pr-1 text-center">{{ po.orderer.name }}</td>
                </tr>
                <tr>
                  <td class="text-bold border-top border-left border-right border-bottom text-orange pl-1 pr-1">Authorised by</td>
                  <td class="border-top border-right border-bottom pl-1 pr-1 text-center">{{ po.authoriser.name }}</td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
        <v-row class="border-top border-left border-right border-bottom mt-4" dense>
          <v-col>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Received</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, index) in po.purchase_order_lines" :key="`l_${index}`">
                  <td>{{ line.description }}</td>
                  <td>{{ line.quantity }}</td>
                  <td>
                    {{ line.quantity_received }}
                    <span v-if="line.date_received">
                      &nbsp;|&nbsp;
                      {{ line.date_received | tinyDate }}
                    </span>
                  </td>
                  <td>{{ line.price | currency }}</td>
                  <td>{{ line.quantity * line.price | currency }}</td>
                </tr>
                <tr><td colspan="5">&nbsp;</td></tr>
                <tr><td colspan="5">&nbsp;</td></tr>
                <tr><td colspan="5">&nbsp;</td></tr>
                <tr><td colspan="5">&nbsp;</td></tr>
                <tr><td colspan="5">&nbsp;</td></tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="col-4 border-bottom border-left border-right">
            <h4 class="text-orange">Delivery address:</h4>
            {{ po.delivery_address }}
          </v-col>
          <v-col class="col-4">&nbsp;</v-col>
          <v-col class="col-4 border-bottom border-left border-right">
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td class="text-orange">Carriage charge</td>
                  <td>{{ po.carriage_charges | currency }}</td>
                </tr>
                <tr>
                  <td class="text-orange">
                    Sub-total
                  </td>
                  <td>
                    {{ po.order_value | currency }}
                  </td>
                </tr>
                <tr>
                  <td class="text-orange">VAT</td>
                  <td>{{ ((po.order_value * 1.2) - po.order_value) | currency }}</td>
                </tr>
                <tr>
                  <td class="text-orange">Total</td>
                  <td>{{ (po.order_value * 1.2) | currency }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <h4 class="text-orange">PLEASE NOTE:</h4>
            <p class="text-bold text-orange text-small">Any discrepancies between items ordered and items delivered will not be accepted without prior approval</p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <p>
              {{ po.orderer.name }}<br />
              <span class="text-bold text-orange text-small">
                {{ po.orderer.title }}
              </span>
            </p>
            <p class="text-center">
              <img width="50px" src="../../assets/bba.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/british_quality_foundation.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/safe_constructor_approved.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/chas.png" alt="" />
              &nbsp;
              <img width="35px" src="../../assets/black_logo.png" alt="" />
              &nbsp;
              <img width="35px" src="../../assets/constructor_line.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/nvq.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/ISO9001_UKAS.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/ISO14001_UKAS.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/ISO45001_UKAS.png" alt="" />
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ViewPurchaseOrder',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      po: {
        supplier: {},
      },
    };
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    getPurchaseOrder() {
      const poId = this.$route.params.id;
      axios.get(`/purchaseOrders/get/${poId}.json?token=${this.token}`)
        .then((response) => {
          this.po = response.data.purchaseOrder;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    print() {
      window.print();
    },
    goBack() {
      this.$router.go('-1');
    },
  },
  mounted() {
    this.getPurchaseOrder();
  },
};
</script>

<style lang="scss">
.po-tc p {
  font-size: 85%;
}
</style>
